import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import carhistory_img from './carhistory_img3.webp';
import icon1 from './icons/shield-check-svgrepo-com.svg';
import icon2 from './icons/clock-svgrepo-com.svg';
import icon3 from './icons/note-edit-svgrepo-com.svg';

function CarHistory_page() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate('/carhistory');
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="sellcar_page">
      <div className="sellcar_page-body">
        <div className="text-content-sellcar">
          <h1 dangerouslySetInnerHTML={{ __html: t('carHistoryTitle') }}></h1>
          <p>{t('carHistoryDescription')}</p>
          <button onClick={handleClick}>{t('carHistoryButton')}</button>
        </div>
        <div className="image-content-carhistory">
          <img src={carhistory_img} alt="Car"></img>
        </div>
      </div>
      <div className="sellcar_page-body2">
        <div className="sellcar_page-body2-content">
          <h2>{t('decideSafelyTitle')}</h2>
          <div className="sellcar_page-body2-content-container">
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon1} alt="icon1" className="sellcar_icon" />
              <h3>{t('advantage1Title')}</h3>
              <p>{t('advantage1Description')}</p>
            </div>
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon2} alt="icon2" className="sellcar_icon" />
              <h3>{t('advantage2Title')}</h3>
              <p>{t('advantage2Description')}</p>
            </div>
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon3} alt="icon3" className="sellcar_icon" />
              <h3>{t('advantage3Title')}</h3>
              <p>{t('advantage3Description')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarHistory_page;
