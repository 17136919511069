import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RangeSelect2 = ({ options = [], placeholder, onChange, minValue = '', maxValue = '', isLoading = false, icon }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMinOpen, setIsMinOpen] = useState(false);
  const [isMaxOpen, setIsMaxOpen] = useState(false);
  const [filterMin, setFilterMin] = useState('');
  const [filterMax, setFilterMax] = useState('');
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsMinOpen(false);
        setIsMaxOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilterMin('');
      setFilterMax('');
      setIsMinOpen(false);
      setIsMaxOpen(false);
    }
  };

  const handleSelect = (type, option) => {
    if (type === 'min') {
      onChange({ min: option.value, max: maxValue });
    } else if (type === 'max') {
      onChange({ min: minValue, max: option.value });
    }

    if (type === 'min') {
      setIsMinOpen(false);
    } else if (type === 'max') {
      setIsMaxOpen(false);
    }
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    onChange({ min: '', max: '' });
    setIsMinOpen(false);
    setIsMaxOpen(false);
    setIsOpen(false);
  };

  const handleAccept = () => {
    setIsOpen(false);
  };

  const handleFilterChange = (type, e) => {
    if (type === 'min') setFilterMin(e.target.value);
    if (type === 'max') setFilterMax(e.target.value);
  };

  const filteredOptions = (filter) =>
    options.filter((option) =>
      option.label.toLowerCase().includes(filter.toLowerCase())
    );

  return (
    <div ref={containerRef} className="range-select-container">
      <div className="range-select-control" onClick={handleToggle}>
        <div className="range-select-placeholder">
          {icon && <img src={icon} className="placeholder-icon" alt="filter icon" />}
          {placeholder}
        </div>
      </div>
      {isOpen && (
        <div className="range-select-menu slide-right">
          {isLoading ? (
            <div className="loading-container">
              <span className="spinner"></span>
            </div>
          ) : (
            <>
              <div className="range-select-columns">
                <div className="range-select-column">
                  <h3>Mínimo</h3>
                  <h4 onClick={() => setIsMinOpen(!isMinOpen)}>
                    {minValue ? `Desde: ${minValue}` : 'Desde'}
                  </h4>
                  {isMinOpen && (
                    <div className="range-select-submenu range-select-submenu-min">
                      {filteredOptions(filterMin).map((option) => (
                        <div
                          key={option.value}
                          className={`range-select-option ${minValue === option.value ? 'selected' : ''}`}
                          onClick={() => handleSelect('min', option)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="range-select-column">
                  <h3>Máximo</h3>
                  <h4 onClick={() => setIsMaxOpen(!isMaxOpen)}>
                    {maxValue ? `Hasta: ${maxValue}` : 'Hasta'}
                  </h4>
                  {isMaxOpen && (
                    <div className="range-select-submenu range-select-submenu-max">
                      {filteredOptions(filterMax).map((option) => (
                        <div
                          key={option.value}
                          className={`range-select-option ${maxValue === option.value ? 'selected' : ''}`}
                          onClick={() => handleSelect('max', option)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="range-select-buttons">
                <button className="clear-all-button" onClick={clearSelection}>
                  {t('clearSelection')}
                </button>
                <button className="close-button" onClick={handleAccept}>
                  {t('accept')}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RangeSelect2;
