import React, { useState, useRef, useEffect, useCallback } from 'react';

const MultiSelect_brands = ({ options = [], placeholder, onChange, value = [], isLoading = false, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [pendingSelections, setPendingSelections] = useState(value);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  // Solo sincronizamos pendingSelections con value al montar el componente
  useEffect(() => {
    setPendingSelections(value);
  }, []); 

  const handleAccept = useCallback(() => {
    onChange(pendingSelections);
    setIsOpen(false);
  }, [onChange, pendingSelections]);

  const handleClickOutside = useCallback((event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (isOpen) {
        // Solo acepta si se seleccionó algo nuevo
        if (pendingSelections.length > 0 && JSON.stringify(pendingSelections) !== JSON.stringify(value)) {
          handleAccept();
        } else {
          setIsOpen(false); // Simplemente cierra el menú
        }
      }
    }
  }, [isOpen, handleAccept, pendingSelections, value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (isOpen && inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      setPendingSelections(value); // Sincronizamos con value al abrir
      setFilter('');
    }
    setIsOpen(!isOpen);
  }, [isOpen, value]);

  const handleSelect = useCallback((option) => {
    setPendingSelections(prev => {
      const isSelected = prev.includes(option.value);
      if (isSelected) {
        return prev.filter(val => val !== option.value);
      }
      return [...prev, option.value];
    });
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  const clearSelection = useCallback((e) => {
    e.stopPropagation();
    setPendingSelections([]);
    onChange([]);
    setIsOpen(false);
  }, [onChange]);

  const { popularOptions, otherOptions } = React.useMemo(() => {
    return splitOptionsWithAndWithoutIcon(options, filter);
  }, [options, filter]);

  return (
    <div ref={containerRef} className={`multi-select-container2 ${isOpen ? 'is-open' : ''}`}>
      <div 
        className={`multi-select-control2 ${value.length > 0 ? 'has-selection' : ''}`}
        onClick={handleToggle}
      >
        <div className={`multi-select-placeholder ${value.length > 0 ? 'placeholder-selected' : ''}`}>
          {icon && <img src={icon} alt="" className="placeholder-icon" />}
          {placeholder}
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-menu2-large">
          <input
            ref={inputRef}
            type="text"
            className="multi-select-input"
            placeholder="Escriba para filtrar..."
            value={filter}
            onChange={handleFilterChange}
          />
          <div className="multi-select-options">
            {popularOptions.length > 0 && (
              <>
                <div className="option-group-title">Marcas más populares</div>
                {popularOptions.map(option => (
                  <div
                    key={option.value}
                    className={`multi-select-option-brands ${pendingSelections.includes(option.value) ? 'selected' : ''}`}
                    onClick={() => handleSelect(option)}
                  >
                    {option.icon && <img src={option.icon} alt="icon" className="option-icon" />}
                    <span className={`multi-select-option-label ${pendingSelections.includes(option.value) ? 'selected' : ''}`}>
                      {option.label}
                      {!isLoading && option.count && ( // Solo muestra el número cuando no está cargando
                        <> - {option.count}</>
                      )}
                    </span>
                    <input
                      type="checkbox"
                      checked={pendingSelections.includes(option.value)}
                      onChange={() => handleSelect(option)}
                      className="multi-select-checkbox"
                    />
                    <span className="custom-checkbox"></span>
                  </div>
                ))}
              </>
            )}
            {otherOptions.length > 0 && (
              <>
                <div className="option-group-title">Otras marcas</div>
                {otherOptions.map(option => (
                  <div
                    key={option.value}
                    className={`multi-select-option-brands ${pendingSelections.includes(option.value) ? 'selected' : ''}`}
                    onClick={() => handleSelect(option)}
                  >
                    <span className={`multi-select-option-label ${pendingSelections.includes(option.value) ? 'selected' : ''}`}>
                      {option.label}
                      {!isLoading && option.count && ( // Solo muestra el número cuando no está cargando
                        <> - {option.count}</>
                      )}
                    </span>
                    <input
                      type="checkbox"
                      checked={pendingSelections.includes(option.value)}
                      onChange={() => handleSelect(option)}
                      className="multi-select-checkbox"
                    />
                    <span className="custom-checkbox"></span>
                  </div>
                ))}
              </>
            )}
          </div>

          
          <div className="multi-select-buttons">
            <button className="clear-all-button" onClick={clearSelection}>
              Borrar Selección
            </button>
            <button className="close-button" onClick={handleAccept}>
              Aceptar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const splitOptionsWithAndWithoutIcon = (options, filter) => {
  const filteredOptions = options
    .filter(option => option.label.toLowerCase().includes(filter.toLowerCase()));

  const popularOptions = filteredOptions
    .filter(option => option.icon)
    .sort((a, b) => a.label.localeCompare(b.label));

  const otherOptions = filteredOptions
    .filter(option => !option.icon)
    .sort((a, b) => a.label.localeCompare(b.label));

  return { popularOptions, otherOptions };
};

export default MultiSelect_brands;