import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import sellcar_img from './blank.png';
import icon1 from './icons/car-auto-svgrepo-com.svg';
import icon2 from './icons/chart-bar-vertical-01-svgrepo-com.svg';
import icon3 from './icons/clock-svgrepo-com.svg';

function Insurance_page() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate('/results');
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="sellcar_page">
      <div className="sellcar_page-body">
        <div className="text-content-sellcar">
          <h1 dangerouslySetInnerHTML={{ __html: t('insuranceTitle') }}></h1>
          <p>{t('insuranceDescription')}</p>
          <button onClick={handleClick}>{t('insuranceButton')}</button>
        </div>
        <div className="image-content-sellcar">
          <img src={sellcar_img} alt="Car"></img>
        </div>
      </div>
      <div className="sellcar_page-body2">
        <div className="sellcar_page-body2-content">
          <h2>{t('advantagesTitle')}</h2>
          <div className="sellcar_page-body2-content-container">
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon1} alt="icon1" className="sellcar_icon" />
              <h3>{t('advantage1Title2')}</h3>
              <p>{t('advantage1Description2')}</p>
            </div>
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon2} alt="icon2" className="sellcar_icon" />
              <h3>{t('advantage2Title2')}</h3>
              <p>{t('advantage2Description2')}</p>
            </div>
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon3} alt="icon3" className="sellcar_icon" />
              <h3>{t('advantage3Title2')}</h3>
              <p>{t('advantage3Description2')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insurance_page;
