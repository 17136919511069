import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import useFetch from './useFetch';  // Importamos la clase useFetch
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';  // Importamos ClipLoader para la animación
import sellcar_img from './media_car.webp';

function Media_page() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeFilter, setActiveFilter] = useState('Todo');

    const exampleNews = {
        total: 6,
        cars: [
            {
                title: "Los coches eléctricos más vendidos en 2024",
                date: "23 Octubre 2024",
                imageUrl: "https://images.coches.com/_vn_/nissan/Qashqai/a7cabe8bdb225e36ab2b04923df59aa2.jpg?p=cc_vn_medium",
            },
            {
                title: "Tecnología híbrida: lo mejor de ambos mundos",
                date: "20 Octubre 2024",
                imageUrl: "https://carnovo.com/wp-content/uploads/2023/11/nissan-qashqain2023-1024x546.jpg",
            },
            {
                title: "Consejos para mantener tu coche en invierno",
                date: "15 Octubre 2024",
                imageUrl: "https://a.ccdn.es/cnet/contents/media/resources/2021/10/1285401.jpg/432x242cut/",
            },
            {
                title: "Los coches eléctricos más vendidos en 2024",
                date: "23 Octubre 2024",
                imageUrl: "https://carwow-es-wp-0.imgix.net/ElectricoCargaOferta.jpg?auto=format&cs=tinysrgb&fit=clip&ixlib=rb-1.1.0&q=60&w=400",
            },
            {
                title: "Tecnología híbrida: lo mejor de ambos mundos",
                date: "20 Octubre 2024",
                imageUrl: "https://carwow-es-wp-0.imgix.net/0x0-Roadster_03-scaled.jpg?auto=format&cs=tinysrgb&fit=clip&ixlib=rb-1.1.0&q=60&w=400",
            },
            {
                title: "Consejos para mantener tu coche en invierno",
                date: "15 Octubre 2024",
                imageUrl: "https://carwow-es-wp-0.imgix.net/Tourneo_Courier_Desert_Island_Blue_018-LOW.jpg?auto=format&cs=tinysrgb&fit=clip&ixlib=rb-1.1.0&q=60&w=400",
            }
        ]
    };

    // API
    //const url = `http://3.95.6.146:8080/news?filter=${activeFilter}`;
    //const { data, isPending, error } = useFetch(url);

    const data = exampleNews;
    const isPending = false;  // Para evitar mostrar la animación de carga
    const error = null;

    const handleClick = () => {
        navigate('/results');
        window.scrollTo({ top: 0 });
    };

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    };

    return (
        <div className="news_page">
          <div className="news_page-body">
            <div className='text-content-news-box'>
              <div className="text-content-news">
                <h1>{t('newsTitle')}</h1>
                <p dangerouslySetInnerHTML={{ __html: t('newsDescription') }}></p>
              </div>

              <div className="image-content-media">
                <img src={sellcar_img} alt="Car"></img>
              </div>
            </div>
            
            {/* Botones de Filtros */}
            <div className="news-filters-container">
              <div className="news-filters">
                {['Todo', 'Actualidad', 'Rankings', 'Reviews', 'Consejos'].map((filter) => (
                  <button
                    key={filter}
                    className={`filter-button-news ${activeFilter === filter ? 'active' : ''}`}
                    onClick={() => handleFilterClick(filter)}
                  >
                    {t(`filter${filter}`)}
                  </button>
                ))}
              </div>
            </div>
    
            {/* Mostrar el estado de la carga o el error */}
            {isPending && (
              <div className="loading-container">
                <ClipLoader color="#5080ed" loading={true} />
              </div>
            )}
            {error && !isPending && (
              <div className="error-message">
                <p>{t('newsError')} {error}</p>
              </div>
            )}
    
            {/* Tarjetas de Noticias */}
            {data && data.total > 0 && !isPending && !error && (
              <div className="news-cards">
                {data.cars.map((news, index) => (
                  <div className="news-card" key={index}>
                    <img src={news.imageUrl} alt={news.title} className="news-image" />
                    <h3 className="news-title">{news.title}</h3>
                    <p className="news-date">{news.date}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }
    
export default Media_page;