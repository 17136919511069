import React from 'react';
import { useTranslation } from 'react-i18next';
import StarsIcon from './icons/stars-svgrepo-com.svg';

const ToggleSwitch = ({ checked, onChange }) => {
    const { t } = useTranslation();

    return (
        <div className="switch-container">
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={onChange} />
                <span className="slider round">
                    <span className="text search">{t('searchText')}</span>
                    <span className="text ai">{t('aiText')}</span>
                </span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
