// src/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './logotest.png';
import { ReactComponent as GlobeIcon } from './icons/globe-svgrepo-com-2.svg';
import RegionSelector from './RegionSelector';
import icon_language from './icons/caret-down-md-svgrepo-com.svg';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showRegionSelector, setShowRegionSelector] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.toUpperCase());
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleRegionSelector = () => {
    setShowRegionSelector(!showRegionSelector);
  };

  const toggleLanguageMenu = () => {
    setShowLanguageMenu(!showLanguageMenu);
  };

  const selectLanguage = (language) => {
    i18n.changeLanguage(language.toLowerCase()); // Cambia el idioma en i18next
    setSelectedLanguage(language);
    setShowLanguageMenu(false);
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <div className="navbar-content">
      <nav className="navbar">
        <Link to="/" className="logo-link">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        
        <div className={`links ${isOpen ? 'open' : ''}`}>
          
          <div className='centered-links'>
            <Link to="/sellcar" className={getLinkClass('/sellcar')}>{t('sellCar')}</Link>
            <Link to="/carhistory" className={getLinkClass('/carhistory')}>{t('carHistory')}</Link>
            <Link to="/insurance" className={getLinkClass('/insurance')}>{t('insurance')}</Link>
            <Link to="/news" className={getLinkClass('/news')}>{t('news')}</Link>
          </div>
          
          <div className="header-buttons">
            <div className="language-menu">
              <button className="header-button" onClick={toggleLanguageMenu}>
                <img 
                  src={icon_language} 
                  alt="Icono de idioma" 
                  className={`language-icon ${showLanguageMenu ? 'rotate' : ''}`} 
                />
                {selectedLanguage}
              </button>
              {showLanguageMenu && (
                <ul className="language-dropdown">
                  <li onClick={() => selectLanguage('ES')}>ES</li>
                  <li onClick={() => selectLanguage('EN')}>EN</li>
                  <li onClick={() => selectLanguage('FR')}>FR</li>
                  <li onClick={() => selectLanguage('DE')}>DE</li>
                </ul>
              )}
            </div>
          </div>


        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}></div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
