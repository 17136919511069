import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import sellcar_img from './sellcar_photo.png';
import icon1 from './icons/tag-svgrepo-com.svg';
import icon2 from './icons/calendar-check-svgrepo-com.svg';
import icon3 from './icons/clock-svgrepo-com.svg';

function SellCar_page() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate('/results');
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="sellcar_page">
      <div className="sellcar_page-body-box">
        <div className="sellcar_page-body">
          <div className="text-content-sellcar">
            <h1 dangerouslySetInnerHTML={{ __html: t('sellCarTitle') }}></h1>
            <p>{t('sellCarDescription')}</p>
            <button onClick={handleClick}>{t('sellCarButton')}</button>
          </div>
          <div className="image-content-sellcar">
            <img src={sellcar_img} alt="Car"></img>
          </div>
        </div>
      </div>
  
      <div className="sellcar_page-body2">
        <div className="sellcar_page-body2-content">
          <h2>{t('howToSellTitle')}</h2>
          <div className="sellcar_page-body2-content-container">
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon1} alt="icon1" className="sellcar_icon" />
              <h3>{t('step1Title')}</h3>
              <p>{t('step1Description')}</p>
            </div>
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon2} alt="icon2" className="sellcar_icon" />
              <h3>{t('step2Title')}</h3>
              <p>{t('step2Description')}</p>
            </div>
            <div className="sellcar_page-body2-content-container-column">
              <img src={icon3} alt="icon3" className="sellcar_icon" />
              <h3>{t('step3Title')}</h3>
              <p>{t('step3Description')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellCar_page;
