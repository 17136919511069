// src/index.js
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to remove 'hidden' class once the mode is determined
const removeHiddenClass = () => {
  document.body.classList.remove('hidden');
};

root.render(
  <React.StrictMode>
    <App onModeDetermined={removeHiddenClass} />
  </React.StrictMode>
);
