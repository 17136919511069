import { useState, forwardRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from './icons/search-magnifying-glass-svgrepo-com.svg';
import { ReactComponent as SearchIconAI } from './icons/stars-svgrepo-com.svg';

const SearchBar3 = forwardRef(({ onSearch, query, useAISearch, onSwitchSearchType }, ref) => {
    const [searchQuery, setSearchQuery] = useState(query || "");
    const { t } = useTranslation();

    // Sincronizar el estado interno con el prop `query` en caso de cambios externos
    useEffect(() => {
        setSearchQuery(query || "");
    }, [query]);

    // Manejar cambios en el input
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Detectar la tecla Enter para ejecutar la búsqueda
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(searchQuery);
        }
    };

    // Cambiar entre búsqueda normal y AI
    const toggleSearchType = () => {
        onSwitchSearchType(!useAISearch);
    };

    return (
        <div className={`search-bar2 ${useAISearch ? "ai-search" : ""}`}>
            {/* Input de búsqueda */}
            <input
                ref={ref}
                type="search"
                placeholder={useAISearch ? t('searchPlaceholderAI') : t('searchPlaceholder')}
                value={searchQuery}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={useAISearch ? "ai-input" : ""}
            />

            {/* Botón de búsqueda */}
            <button
                type="button"
                onClick={() => onSearch(searchQuery)}
                className={useAISearch ? "ai-button" : ""}
            >
                {useAISearch ? <SearchIconAI /> : <SearchIcon />}
            </button>
        </div>
    );
});

export default SearchBar3;
