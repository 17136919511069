import React, { useState, useRef, useEffect } from 'react';

const LocationSelect2 = ({ options = [], placeholder, onChange, value = [], isLoading = false, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(value);  // Estado local para las opciones seleccionadas
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        // Llamamos a handleAccept si el menú está abierto
        if (isOpen) {
          handleAccept();  // Llama a handleAccept cuando se cierra el menú
          setIsOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, selectedOptions]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (isOpen && inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilter('');
    }
  };

  const handleSelect = (option) => {
    const isSelected = selectedOptions.includes(option.label);
    let newSelectedOptions;
    if (isSelected) {
      newSelectedOptions = selectedOptions.filter(item => item !== option.label);
    } else {
      newSelectedOptions = [...selectedOptions, option.label];
    }
    setSelectedOptions(newSelectedOptions);  // Actualizar el estado local
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    setSelectedOptions([]);  // Limpiar la selección en el estado local
    onChange([]);  // Llamar a onChange con un array vacío para hacer una nueva búsqueda
    setIsOpen(false);  // Cerrar el menú
  };

  const handleAccept = () => {
    // Comparar los filtros actuales con los seleccionados previamente
    const newSelectedOptions = selectedOptions.map(option => option.split(' ')[0]);
  
    // Solo llamar a onChange si los filtros han cambiado
    if (newSelectedOptions.length !== value.length || !newSelectedOptions.every((val, index) => val === value[index])) {
      onChange(newSelectedOptions);
    }
    setIsOpen(false);
  };

  const filteredOptions = capitalizeAndSortOptions(options).filter(option =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div ref={containerRef} className={`multi-select-container2 ${isOpen ? 'is-open' : ''}`}>
      <div 
        className={`location-select-control2 ${selectedOptions.length > 0 ? 'has-selection' : ''}`}
        onClick={handleToggle}
      >
        <div className={`multi-select-placeholder ${selectedOptions.length > 0 ? 'placeholder-selected' : ''}`}>
          {icon && <img src={icon} className="placeholder-icon" />}
          {placeholder}
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-menu2">
          {isLoading ? (
            <div className="loading-container2">
              <span className="spinner2"></span>
            </div>
          ) : (
            <>
              <input
                ref={inputRef}
                type="text"
                className="multi-select-input"
                placeholder="Escriba para filtrar..."
                value={filter}
                onChange={handleFilterChange}
              />
              <div className="multi-select-options">
                {filteredOptions.map(option => (
                  <div
                    key={option.value}
                    className={`multi-select-option ${selectedOptions.includes(option.label) ? 'selected' : ''}`}
                    onClick={() => handleSelect(option)}
                  >
                    <span className={`multi-select-option-label ${selectedOptions.includes(option.label) ? 'selected' : ''}`}>
                      {option.label}
                    </span>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(option.label)}
                      onChange={() => handleSelect(option)}
                      className="multi-select-checkbox"
                    />
                    <span className="custom-checkbox"></span>
                  </div>
                ))}
              </div>
              <div className="multi-select-buttons">
                <button className="clear-all-button" onClick={clearSelection}>
                  Borrar Selección
                </button>
                <button className="close-button" onClick={handleAccept}>
                  Aceptar
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const capitalizeAndSortOptions = (options) => {
  return options
    .map(option => ({
      ...option,
      label: option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export default LocationSelect2;
