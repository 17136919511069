import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from './icons/filter-svgrepo-com.svg';
import { ReactComponent as OrderIcon } from './icons/list-svgrepo-com.svg';

const Center_header = (props) => {
    const { total, order, onOrderChange, onToggleFilters, isPending } = props;
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [displayedTotal, setDisplayedTotal] = useState("0000"); // Número mostrado
    const [previousTotal, setPreviousTotal] = useState("0000"); // Número anterior

    const formatNumberWithThousandsSeparators = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (isPending) {
            // Mantener el número anterior mientras se cargan los nuevos datos
            setDisplayedTotal(previousTotal);
        } else {
            // Actualizar el número mostrado y guardar el nuevo como anterior
            const formattedTotal = formatNumberWithThousandsSeparators(total);
            setDisplayedTotal(formattedTotal);
            setPreviousTotal(formattedTotal);
        }
    }, [isPending, total, previousTotal]);

    const handleOrderChange = (event) => {
        onOrderChange(event.target.value);
        setIsDropdownVisible(false); // Oculta el dropdown después de seleccionar en móviles
    };

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    return (
        <div className="center_header">
            <div className="car_count">
                {displayedTotal} <span className="smaller-text">{t("carsFound")}</span>
            </div>

            <div className="filter_order">
                {isMobile ? (
                    <>
                        <button
                            className="toggle-filters-btn"
                            onClick={() => {
                                onToggleFilters();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                        >
                            <FilterIcon className="filter-icon" />
                            {t("filter")}
                        </button>

                        <div className={`select-wrapper ${isFocused || order ? "focused" : ""}`}>
                            <button className="order-button" onClick={toggleDropdown}>
                                <OrderIcon className="order-icon" />
                                {t("sort")}
                            </button>
                            {isDropdownVisible && (
                                <div className="dropdown-options">
                                    <div onClick={() => handleOrderChange({ target: { value: "relevant" } })}>
                                        {t("relevant")}
                                    </div>
                                    <div onClick={() => handleOrderChange({ target: { value: "car_year" } })}>
                                        {t("newest")}
                                    </div>
                                    <div onClick={() => handleOrderChange({ target: { value: "car_year" } })}>
                                        {t("oldest")}
                                    </div>
                                    <div onClick={() => handleOrderChange({ target: { value: "car_mileage" } })}>
                                        {t("highestMileage")}
                                    </div>
                                    <div onClick={() => handleOrderChange({ target: { value: "car_mileage" } })}>
                                        {t("lowestMileage")}
                                    </div>
                                    <div onClick={() => handleOrderChange({ target: { value: "car_km" } })}>
                                        {t("highestPrice")}
                                    </div>
                                    <div onClick={() => handleOrderChange({ target: { value: "car_km" } })}>
                                        {t("lowestPrice")}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className={`select-wrapper ${isFocused || order ? "focused" : ""}`}>
                        <label htmlFor="order" className="order-placeholder">
                            {t("sort")}
                        </label>
                        <select
                            id="order"
                            name="order"
                            value={order}
                            onChange={handleOrderChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        >
                            <option value="relevant">{t("relevant")}</option>
                            <option value="newest">{t("newest")}</option>
                            <option value="oldest">{t("oldest")}</option>
                            <option value="highest_mileage">{t("highestMileage")}</option>
                            <option value="lowest_mileage">{t("lowestMileage")}</option>
                            <option value="highest_price">{t("highestPrice")}</option>
                            <option value="car_price">{t("lowestPrice")}</option>
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Center_header;
