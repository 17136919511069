import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import categoria_aux from './sellcar_photo1.png';

const Categories_menu = () => {
    const { t } = useTranslation();

    return (
        <div className="categories_menu">
            <h3 className="titulo-marcas" dangerouslySetInnerHTML={{ __html: t('searchByLifestyle') }} />


            <div className="grid-columns">

                <div className="grid-item">
                    <h4 className="grid-title">{t('city')}</h4>
                </div>

                <div className="grid-item">
                    <h4 className="grid-title">{t('sport')}</h4>
                </div>

                <div className="grid-item">
                    <h4 className="grid-title">{t('offroad')}</h4>
                </div>

                <div className="grid-item">
                    <h4 className="grid-title">{t('family')}</h4>
                </div>

                <div className="grid-item">
                    <h4 className="grid-title">{t('eco')}</h4>
                </div>

        </div>

        </div>
    );
}
  
export default Categories_menu;