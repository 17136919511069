import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import MultiSelect from './MultiSelect2';
import MultiSelect_brands from './MultiSelect_brands';
import OneSelect from './OneSelect2';
import RangeSelect2 from './RangeSelect2';
import LocationSelect from './LocationSelect2';

/*filters icons*/
import countryIcon from './icons/globe-svgrepo-com.svg';
import brandIcon from './icons/car-auto-svgrepo-com.svg';
import modelIcon from './icons/adjustment-svgrepo-com.svg';
import gearboxIcon from './icons/settings-svgrepo-com.svg';
import priceIcon from './icons/tag-svgrepo-com.svg';
import yearIcon from './icons/calendar-check-svgrepo-com.svg';
import kmIcon from './icons/chart-bar-vertical-01-svgrepo-com.svg';
import fuelIcon from './icons/water-drop-svgrepo-com.svg';
import doorsIcon from './icons/car-door-left-1-svgrepo-com.svg';
import seatsIcon from './icons/user-02-svgrepo-com.svg';
import colorIcon from './icons/brush-svgrepo-com.svg';

/*brand icons*/
import Audi_icon from './brand_icons/audi.svg';
import Bmw from './brand_icons/bmw.svg';
import Citroen from './brand_icons/citroen.svg';
import Ford from './brand_icons/ford.svg';
import Infiniti from './brand_icons/infiniti.svg';
import Jaguar from './brand_icons/jaguar.svg';
import Land_rover from './brand_icons/land-rover.svg';
import Mitsubishi from './brand_icons/mitsubishi.svg';
import Opel from './brand_icons/opel.svg';
import Porsche from './brand_icons/porsche.svg';
import Toyota from './brand_icons/toyota.svg';
import Volkswagen from './brand_icons/volkswagen.svg';
import Volvo from './brand_icons/volvo.svg';
import Alfa_romeo from './brand_icons/alfa-romeo-alt.svg';
import Hyundai from './brand_icons/hyundai.svg';
import Jeep from './brand_icons/jeep.svg';
import Seat from './brand_icons/seat.svg';
import Tesla from './brand_icons/tesla.svg';
import Mazda from './brand_icons/mazda.svg';
import Honda from './brand_icons/honda.svg';
import Mclaren from './brand_icons/mclaren.svg';
import Mini from './brand_icons/mini.svg';
import Smart from './brand_icons/smart.svg';
import Subaru from './brand_icons/subaru.svg';
import Suzuki from './brand_icons/suzuki.svg';
import Mercedes from './brand_icons/mercedes.svg';
import Cupra from './brand_icons/cupra.svg';
import Dacia from './brand_icons/dacia.svg';

import Nissan from './brand_icons/nissan.svg';
import Peugeot from './brand_icons/peugeot.svg';
import Renault from './brand_icons/renault.svg';
import Kia from './brand_icons/kia.svg';

/*gearbox icons*/
import automaticIcon from './icons/automatic_icon.svg'
import manualIcon from './icons/manual_icon.svg'

/*colors icons*/
import redIcon from './icons/icon_colors/red-circle-svgrepo-com.svg'
import greenIcon from './icons/icon_colors/green-circle-svgrepo-com.svg'
import blackIcon from './icons/icon_colors/black-circle-svgrepo-com.svg'
import yellowIcon from './icons/icon_colors/yellow-circle-svgrepo-com.svg'
import greyIcon from './icons/icon_colors/grey-icon.svg'
import purpleIcon from './icons/icon_colors/purple-circle-svgrepo-com.svg'
import orangeIcon from './icons/icon_colors/orange-circle-svgrepo-com.svg'
import blueIcon from './icons/icon_colors/blue-circle-svgrepo-com.svg'
import brownIcon from './icons/icon_colors/brown-circle-svgrepo-com.svg'
import whiteIcon from './icons/icon_colors/white-icon.svg'

const optionIcons = {
    car_gearbox: {
        "automatic": automaticIcon,
        "manual": manualIcon
    },

    car_color: {
        "red": redIcon,
        "yellow": yellowIcon,
        "black": blackIcon,
        "purple": purpleIcon,
        "grey": greyIcon,
        "brown": brownIcon,
        "blue": blueIcon,
        "orange": orangeIcon,
        "white": whiteIcon,
        "green": greenIcon
    },
    car_brand: {
        "audi": Audi_icon,
        "bmw": Bmw,
        "citroen": Citroen,
        "ford": Ford,
        "infiniti": Infiniti,
        "jaguar": Jaguar,
        "land rover": Land_rover,
        "mitsubishi": Mitsubishi,
        "opel": Opel,
        "porsche": Porsche,
        "toyota": Toyota,
        "volkswagen": Volkswagen,
        "volvo": Volvo,
        "alfa romeo": Alfa_romeo,
        "hyundai": Hyundai,
        "jeep": Jeep,
        "seat": Seat,
        "tesla": Tesla,
        "mazda": Mazda,
        "honda": Honda,
        "mclaren": Mclaren,
        "mini": Mini,
        "smart": Smart,
        "subaru": Subaru,
        "suzuki": Suzuki,
        "mercedes": Mercedes,
        "cupra": Cupra,
        "nissan": Nissan,
        "dacia": Dacia,
        "renault": Renault,
        "kia": Kia,
        "peugeot": Peugeot
    },
};
const predefinedRangeOptions = [
    { value: '1000', label: '1,000' },
    { value: '5000', label: '5,000' },
    { value: '10000', label: '10,000' },
    // Agrega más opciones predefinidas
];


const Filters = ({ filters, onFilterChange, onClose, onRemoveAllFilters, totalCars, onFilterOptionsUpdate, query}) => {
    
    const { t } = useTranslation();

    /**ESTAT COMPONENT**/

    /*guarda els filtres seleccionats per l'usuari + copia de "filters"*/
    const [internalFilters, setInternalFilters] = useState(filters);
    /*guarda les opcions de cada filtre del backend*/
    const [filterOptions, setFilterOptions] = useState({
        car_location_country: [],
        car_brand: [],
        car_model: [],
        car_gearbox: [
            { value: 'manual', label: 'manual', icon: optionIcons.car_gearbox['manual'] },
            { value: 'automático', label: 'automático', icon: optionIcons.car_gearbox['automático'] }
        ],
        car_fuel: [],
        car_min_price: [],
        car_max_price: [],
        car_min_km: [],
        car_max_km: [],
        car_min_year: [],
        car_max_year: [],
        car_doors: [],
        car_seats: [],
        car_color: [
            { value: 'rojo', label: 'rojo', icon: optionIcons.car_color['rojo'] },
            { value: 'amarillo', label: 'amarillo', icon: optionIcons.car_color['amarillo'] },
            { value: 'negro', label: 'negro', icon: optionIcons.car_color['negro'] },
            { value: 'blanco', label: 'blanco', icon: optionIcons.car_color['blanco'] },
            { value: 'azul', label: 'azul', icon: optionIcons.car_color['azul'] },
            { value: 'lila', label: 'lila', icon: optionIcons.car_color['lila'] },
            { value: 'gris', label: 'gris', icon: optionIcons.car_color['gris'] },
            { value: 'marron', label: 'marrón', icon: optionIcons.car_color['marron'] },
            { value: 'naranja', label: 'naranja', icon: optionIcons.car_color['naranja'] },
            { value: 'verde', label: 'verde', icon: optionIcons.car_color['verde'] }
        ]
    });
    /*indica si les opcions dels filtres s'estan carregant*/
    const [isLoadingCars, setIsLoadingCars] = useState(true);


    /*EFECTES SECUNDARIS*/

    /*sincronització filtres interns quan el propt filters canvia*/
    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);

    // Log para observar cambios en filtros internos y externos
    useEffect(() => {
        console.log("Filters (props) updated:", filters);
        console.log("InternalFilters (state) updated:", internalFilters);
    }, [filters, internalFilters]);


    /*FUNCIONS PRINCIPALS*/

    /*Maneja cambios en los filtros seleccionados y los sincroniza con el estado interno y la prop onFilterChange*/
    const handleFilterChange = (selectedOptions, { name }) => {
        const newFilters = { ...internalFilters, [name]: selectedOptions };
        setInternalFilters(newFilters);
        onFilterChange(newFilters);
    };

    /*Específica para los filtros de rango (precio, año, kilómetros), actualiza los valores mínimo y máximo.*/
    const handleRangeChange = (selectedRange, { minName, maxName }) => {
        const newFilters = {
            ...internalFilters,
            [minName]: selectedRange.min,
            [maxName]: selectedRange.max
        };
        setInternalFilters(newFilters);
        onFilterChange(newFilters);
    };

    /*Realiza una llamada al backend para obtener las opciones de filtros disponibles en base a los filtros actuales. */
    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                setIsLoadingCars(true);
                const options = await getFilterOptions(internalFilters, query, t);
                setFilterOptions(options);
                //onFilterOptionsUpdate(options); // Actualizar en Results_page
            } catch (error) {
                console.error('Error fetching filter options:', error);
                const emptyOptions = {
                    car_brand: [],
                    car_model: [],
                    car_location_country: [],
                    car_gearbox: [],
                    car_fuel: [],
                    car_doors: [],
                    car_seats: [],
                    car_color: [],
                };
                setFilterOptions(emptyOptions);
                onFilterOptionsUpdate(emptyOptions);
            } finally {
                setIsLoadingCars(false);
            }
        };
    
        fetchFilterOptions();
    }, [internalFilters, onFilterOptionsUpdate, query, t]);


    /*RENDERITZACIÓ DE FILTRES*/
    const renderRangeSelect = (minName, maxName, value, options, placeholder, icon) => (
        <div className="select-container">
            <RangeSelect2
                options={options}
                value={value}
                onChange={selected => handleRangeChange(selected, { minName, maxName })}
                placeholder={placeholder}
                icon={icon}
                isLoading={false}
            />
        </div>
    );

    const renderMultiSelect = (name, value, options, placeholder, icon, isDisabled = false) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <MultiSelect
                options={options.map(option => ({
                    ...option,
                    icon: optionIcons[name] ? optionIcons[name][option.label.split(" ")[0]] : null // Asigna ícono si existe
                }))}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}  // Pasar el estado de carga al componente MultiSelect
                isDisabled={isDisabled}
            />
        </div>
    );

    const renderMultiSelect_brands = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <MultiSelect_brands
                options={options.map(option => ({
                    ...option,
                    icon: optionIcons[name] ? optionIcons[name][option.label.split(" ")[0]] : null // Asigna ícono si existe
                }))}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}  // Pasar el estado de carga al componente MultiSelect
            />
        </div>
    );

    const renderLocationSelect = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <LocationSelect
                options={options}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}
            />
        </div>
    );

    const renderOneSelect = (name, value, options, placeholder, icon) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <OneSelect
                options={options.map(option => ({
                    ...option,
                    icon: optionIcons[name] ? optionIcons[name][option.label.split(" ")[0]] : null // Asigna ícono si existe
                }))}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
                icon={icon}
                isLoading={isLoadingCars}  // Pasar el estado de carga al componente OneSelect
            />
        </div>
    );


    return (
        <div className="filters">
            <div className="filters-top">
                <button className="remove-all-filters-button" onClick={onRemoveAllFilters}>{t('removeFilters')}</button>
                <button className="close-filters-button" onClick={onClose}>✕</button>
            </div>
            <div className="filter">{renderLocationSelect("car_location_country", internalFilters.car_location_country, filterOptions.car_location_country, t('location'), countryIcon)}</div>
            <div className="filter">{renderMultiSelect_brands("car_brand", internalFilters.car_brand, filterOptions.car_brand, t('brand'), brandIcon)}</div>
            <div className="filter">{renderMultiSelect("car_model", internalFilters.car_model, filterOptions.car_model, t('model'), modelIcon, !(internalFilters.car_brand && internalFilters.car_brand.length > 0))}</div>
            <div className="filter">{renderOneSelect("car_gearbox", internalFilters.car_gearbox, filterOptions.car_gearbox, t('gearbox'), gearboxIcon)}</div>
            <div className="filter">{renderMultiSelect("car_fuel", internalFilters.car_fuel, filterOptions.car_fuel, t('fuel'), fuelIcon)}</div>
            <div className="filters_space"></div>
            <div className="filter">{renderRangeSelect("car_min_price", "car_max_price", { min: internalFilters.car_min_price, max: internalFilters.car_max_price }, predefinedRangeOptions, t('price'), priceIcon)}</div>
            <div className="filter">{renderRangeSelect("car_min_year", "car_max_year", { min: internalFilters.car_min_year, max: internalFilters.car_max_year }, predefinedRangeOptions, t('year'), yearIcon)}</div>
            <div className="filter">{renderRangeSelect("car_min_km", "car_max_km", { min: internalFilters.car_min_km, max: internalFilters.car_max_km }, predefinedRangeOptions, t('kilometers'), kmIcon)}</div>

            <div className="filters_space"></div>
            <div className="filter">{renderOneSelect("car_doors", internalFilters.car_doors, filterOptions.car_doors, t('doors'), doorsIcon)}</div>
            <div className="filter">{renderOneSelect("car_seats", internalFilters.car_seats, filterOptions.car_seats, t('seats'), seatsIcon)}</div>
            <div className="filter">{renderMultiSelect("car_color", internalFilters.car_color, filterOptions.car_color, t('color'), colorIcon)}</div>
            
            {/* Botón "Mostrar coches" solo en versión móvil */}
            {window.innerWidth <= 768 && (
                <div className="filters-bottom">
                    <button 
                        className="search-cars-button" 
                        onClick={() => {
                            onClose();  // Cierra el panel de filtros
                            window.scrollTo({ top: 0, behavior: 'smooth' });  // Desplaza la página hacia arriba
                        }}
                    >
                        {isLoadingCars ? (
                            <span className="spinner"></span> // Rueda de carga
                        ) : (
                            `${t('showCars', { count: totalCars.toLocaleString() })}`
                        )}
                    </button>
                </div>  
            )}
        </div>
    );
};

export default Filters;




const getFilterOptions = async (activeFilters, query, t) => {
    
    try {
        const activeFiltersString = JSON.stringify(activeFilters, (key, value) => {
            if (Array.isArray(value) && value.length === 1) {
                return value[0];
            }
            return value;
        });

        const baseUrl = "http://3.95.6.146:8080/get_filters/";
        const url = `${baseUrl}?active_filters=${activeFiltersString}&data=${encodeURIComponent(query)}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const options = {};

        for (const [key, value] of Object.entries(data)) {
            options[key] = Object.keys(value).map(option => ({
                value: option,
                label: t(`Filters.${key}.${option}`, option), // Traduce la etiqueta con un fallback al texto original
                count: value[option] ? value[option].toLocaleString('en-US').replace(/,/g, '.') : null // Formato de números
            }));
        }
        return options;

    } catch (error) {
        console.error('Error fetching filter options:', error);
        return {
            car_brand: [],
            car_model: [],
            car_location_country: [],
            car_gearbox: [
                { value: 'manual', label: t('Filters.car_gearbox.manual', 'manual'), icon: optionIcons.car_gearbox['manual'] },
                { value: 'automatic', label: t('Filters.car_gearbox.automatic', 'automatic'), icon: optionIcons.car_gearbox['automatic'] }
            ],
            car_fuel: [
                { value: 'diesel', label: t('Filters.car_fuel.diesel', 'diesel') },
                { value: 'gasoline', label: t('Filters.car_fuel.gasoline', 'gasoline') },
                { value: 'electric', label: t('Filters.car_fuel.diesel', 'electric') },
                { value: 'gas', label: t('Filters.car_fuel.gas', 'gas') },
                { value: 'cng', label: t('Filters.car_fuel.CNG', 'CNG') },
                { value: 'hybrid-electric', label: t('Filters.car_fuel.hybrid-electric', 'Hybrid-Electric') },
                { value: 'hybrid', label: t('Filters.car_fuel.hybrid', 'Hybrid') },
                { value: 'plug-in hybrid', label: t('Filters.car_fuel.plug-inHybrid', 'Plug-in Hybrid') },
                { value: 'other', label: t('Filters.car_fuel.other', 'other') },
            ],
            car_color: [
                { value: 'rojo', label: t('Filters.car_color.red', 'rojo'), icon: optionIcons.car_color['rojo'] },
                { value: 'amarillo', label: t('Filters.car_color.yellow', 'amarillo'), icon: optionIcons.car_color['amarillo'] },
                // Más opciones traducidas
            ]
        };
    }
};
